import Section from "../SharedComponents/Section";
import img1 from "../photos/mentor1.jpg";
import img2 from "../photos/mentor2.jpg";
import img3 from "../photos/mentor3.jpg";
const MentorsPage = () => (
    <div className="min-h-screen pt-20">
      <Section>
        <h1 className="text-5xl font-bold mb-12">Our Mentors</h1>
        <p className="text-xl text-gray-600 mb-12 max-w-3xl">
          We are honored to have a dedicated group of mentors who bring diverse expertise and experience 
          to guide Adroid Foundation. Our mentors are leaders in education, business, and community 
          development who help us create impactful programs.
        </p>
        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              name: "Dr. Deepak Gupta",
              role: "Educationalist/Researcher",
              image: img1,
              bio: "Dedicated to transforming education through innovative teaching methodologies."
            },
            {
              name: "Mr. Ravinder Kumar Gupta",
              role: "Entrepreneur",
              image: img2,
              bio: "Brings extensive experience in strategic planning and organizational development."
            },
            {
              name: "Mr. Rakesh Gupta",
              role: "Entreprenuer",
              image: img3,
              bio: "Passionate about creating sustainable community development programs."
            }
          ].map((mentor, index) => (
            <div key={index} className="bg-white p-8 rounded-lg shadow-lg text-center">
              <img 
                src={mentor.image} 
                alt={mentor.name} 
                className="w-full h-48 object-contain rounded-md mx-auto mb-6"
              />
              <h3 className="text-2xl font-bold mb-2">{mentor.name}</h3>
              <p className="text-gray-600 mb-4">{mentor.role}</p>
              <p className="text-gray-700">{mentor.bio}</p>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );

  export default MentorsPage