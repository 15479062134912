import Section from "../SharedComponents/Section";
import { ChevronRight } from 'lucide-react';
const BlogPage = () => (
    <div className="min-h-screen pt-20">
      <Section>
        <h1 className="text-5xl font-bold mb-12">Latest Updates</h1>
        <div className="grid md:grid-cols-2 gap-12">
          {[
            {
              title: "Launch of Skill-Based School in Rohini",
              date: "March 15, 2024",
              category: "Foundation Updates",
              image: "/api/placeholder/600/400",
              excerpt: "We're excited to announce the launch of our new skill-based school in Rohini..."
            },
            {
              title: "Scholarship Applications Now Open",
              date: "March 10, 2024",
              category: "Opportunities",
              image: "/api/placeholder/600/400",
              excerpt: "Applications are now open for our 2024-25 scholarship program..."
            },
            {
              title: "Financial Literacy Workshop Success",
              date: "March 5, 2024",
              category: "Events",
              image: "/api/placeholder/600/400",
              excerpt: "Over 100 families participated in our recent financial literacy workshop..."
            },
            {
              title: "Student Success Story: Priya's Journey",
              date: "March 1, 2024",
              category: "Success Stories",
              image: "/api/placeholder/600/400",
              excerpt: "Read about how our programs helped Priya achieve her dreams..."
            }
          ].map((post, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img 
                src={post.image} 
                alt={post.title} 
                className="w-full h-64 object-cover"
              />
              <div className="p-8">
                <div className="flex items-center mb-4">
                  <span className="text-sm text-gray-500">{post.date}</span>
                  <span className="mx-2 text-gray-300">•</span>
                  <span className="text-sm text-gray-600">{post.category}</span>
                </div>
                <h3 className="text-2xl font-bold mb-4">{post.title}</h3>
                <p className="text-gray-600 mb-6">{post.excerpt}</p>
                <a href="#" className="flex items-center text-black font-medium hover:underline">
                  Read more <ChevronRight className="ml-2" size={16} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
export default BlogPage  