import Button from "../SharedComponents/Button";
import Section from "../SharedComponents/Section";
import { ChevronRight } from 'lucide-react';
import imgMain from "../photos/banner.png";
import img1 from "../photos/homePage1.png";
import img2 from "../photos/homePage2.png";
import img3 from "../photos/homePage3.png";
const HomePage = () => {
  const updates = [
    {
      title: "Launch of Skill-Based School",
      description: "New campus opening in Rohini",
      image: img1
    },
    {
      title: "Scholarship Applications",
      description: "Applications now open for 2024-25",
      image: img2
    },
    {
      title: "Financial Literacy Workshop",
      description: "Join us for an empowering education workshop",
      image: img3
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section with Banner */}
      <section className="relative h-screen">
        {/* Banner Image */}
        <div className="absolute inset-0 z-0">
          <img
            src={imgMain}
            alt="Education Banner"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50" />
        </div>

        {/* Hero Content */}
        <div className="relative z-10 pt-32 pb-20 px-4 sm:px-6 lg:px-8 h-full flex items-center">
          <div className="max-w-7xl mx-auto text-white">
            <h1 className="text-5xl md:text-7xl font-bold mb-8">
              Transforming Lives Through Quality Education
            </h1>
            <p className="text-xl md:text-2xl mb-12 max-w-3xl">
              Welcome to Adroid Foundation! We believe in creating an environment where young minds thrive, 
              empowering communities through quality education, skill development, and holistic growth.
            </p>
            <div className="flex flex-wrap gap-4">
              <Button>Learn More</Button>
              <Button variant="secondary">Get Involved</Button>
            </div>
          </div>
        </div>
      </section>

      {/* Updates Section */}
      <Section className="bg-gray-50">
        <h2 className="text-4xl font-bold mb-12">Latest Updates</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {updates.map((update, index) => (
            <div key={index} className="bg-white rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
              {/* Image Container */}
              <div className="h-48 overflow-hidden">
                <img
                  src={update.image}
                  alt={update.title}
                  className="w-full h-full object-cover"
                />
              </div>
              {/* Content */}
              <div className="p-8">
                <h3 className="text-2xl font-bold mb-4">{update.title}</h3>
                <p className="text-gray-600 mb-6">{update.description}</p>
                <a href="#" className="flex items-center text-black font-medium hover:underline">
                  Read more <ChevronRight className="ml-2" size={16} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default HomePage;