import { Link } from "react-router-dom";

const NavLink = ({ to, children }) => (
    <Link 
      to={to} 
      className="text-gray-700 hover:text-black transition-colors px-4 py-2"
    >
      {children}
    </Link>
  );

  export default NavLink;