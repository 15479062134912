import Button from "../SharedComponents/Button";
import Section from "../SharedComponents/Section";
import { Mail, Phone, MapPin } from 'lucide-react';

const ContactPage = () => (
    <div className="min-h-screen pt-20">
      <Section>
        <div className="grid md:grid-cols-2 gap-12">
          <div>
            <h1 className="text-5xl font-bold mb-8">Get in Touch</h1>
            <p className="text-xl text-gray-600 mb-8">
              Have questions or want to get involved? We'd love to hear from you.
            </p>
            <div className="space-y-4">
              <div className="flex items-center">
                <Mail className="mr-4" />
                <span>adroidconnectz@gmail.com</span>
              </div>
              <div className="flex items-center">
                <Phone className="mr-4" />
                <span>+91-9899265660</span>
              </div>
              <div className="flex items-center">
                <MapPin className="mr-4" />
                <span>2nd Floor, A-16, Sector-19, Rohini, Delhi-110089, INDIA</span>
              </div>
            </div>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <form className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
                <input type="text" className="w-full px-4 py-2 border rounded-lg" />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                <input type="email" className="w-full px-4 py-2 border rounded-lg" />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Message</label>
                <textarea className="w-full px-4 py-2 border rounded-lg" rows={4}></textarea>
              </div>
              <Button className="w-full">Send Message</Button>
            </form>
          </div>
        </div>
      </Section>
    </div>
  );


  export default ContactPage;