const Button = ({ children, variant = 'primary', className = '', onClick }) => {
    const baseStyles = 'px-6 py-3 rounded-full font-medium transition-all duration-300';
    const variants = {
      primary: 'bg-black text-white hover:bg-gray-800',
      secondary: 'border-2 border-black text-black hover:bg-black hover:text-white',
    };
    
    return (
      <button 
        onClick={onClick} 
        className={`${baseStyles} ${variants[variant]} ${className}`}
      >
        {children}
      </button>
    );
  };

  export default Button;