import Section from "../SharedComponents/Section";
import { BookOpen, Heart, Users, Trophy, ChevronRight } from 'lucide-react';
import img1 from "../photos/initiatives1.png";
import img2 from "../photos/initiatives2.png";
import img3 from "../photos/initiatives3.png";
import img4 from "../photos/initiatives4.png";

const InitiativesPage = () => {
  const initiatives = [
    {
      icon: <BookOpen size={32} className="mb-6 text-blue-600" />,
      title: "Skill Development Workshops",
      description: "Programs on financial literacy, entrepreneurship, digital skills, and more.",
      points: [
        "Financial Management Workshops",
        "Digital Literacy Programs",
        "Entrepreneurship Training",
        "Professional Development Courses"
      ],
      image: img1
    },
    {
      icon: <Heart size={32} className="mb-6 text-red-600" />,
      title: "Health and Wellbeing Programs",
      description: "Initiatives focusing on mental health, physical wellbeing, and family health awareness.",
      points: [
        "Mental Health Support",
        "Physical Fitness Programs",
        "Family Health Workshops",
        "Nutrition Education"
      ],
      image: img2
    },
    {
      icon: <Users size={32} className="mb-6 text-green-600" />,
      title: "Educational Outreach",
      description: "Providing resources to underprivileged children and adults, focusing on literacy and skills training.",
      points: [
        "Adult Literacy Programs",
        "After-School Support",
        "Resource Distribution",
        "Community Learning Centers"
      ],
      image: img3
    },
    {
      icon: <Trophy size={32} className="mb-6 text-purple-600" />,
      title: "Scholarship & Support",
      description: "Supporting academically talented students from underserved backgrounds.",
      points: [
        "Merit-based Scholarships",
        "Need-based Financial Aid",
        "Academic Support Services",
        "Career Guidance"
      ],
      image: img4
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      <Section>
        <h1 className="text-5xl font-bold mb-16 text-center">Community Initiatives</h1>
        
        <div className="grid md:grid-cols-2 gap-12">
          {initiatives.map((initiative, index) => (
            <div 
              key={index} 
              className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow group"
            >
              {/* Initiative Image */}
              <div className="h-64 overflow-hidden relative">
                <img
                  src={initiative.image}
                  alt={initiative.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
                {/* Title Overlay */}
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 flex items-center">
                  {initiative.icon}
                  <h3 className="text-2xl font-bold ml-4">{initiative.title}</h3>
                </div>
              </div>
              
              {/* Initiative Description */}
              <div className="p-8">
                <p className="text-gray-600 mb-6 leading-relaxed">
                  {initiative.description}
                </p>
                
                {/* Initiatives List */}
                <ul className="space-y-2">
                  {initiative.points.map((point, idx) => (
                    <li key={idx} className="flex items-center text-gray-700">
                      <ChevronRight size={16} className="mr-2 text-blue-500" />
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default InitiativesPage;