import Section from "../SharedComponents/Section";
import img1 from "../photos/vision-mission.png";
import img2 from "../photos/objective1.png";
import img3 from "../photos/objective2.png";
import img4 from "../photos/objective3.png";
import img5 from "../photos/objective4.png";
const MissionVisionPage = () => {
  const objectives = [
    {
      title: "Promote Skill-Based Learning",
      description: "Equip students with practical skills relevant to today's world, including critical thinking, teamwork, and adaptability.",
      image: img2
    },
    {
      title: "Foster Financial Literacy",
      description: "Enable communities to gain financial independence and better manage resources.",
      image: img3
    },
    {
      title: "Encourage Lifelong Learning",
      description: "Instill a passion for knowledge and growth beyond traditional classroom walls.",
      image: img4
    },
    {
      title: "Support Community Engagement",
      description: "Encourage students and families to engage in local initiatives and empower communities through shared knowledge.",
      image: img5
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      {/* Vision and Mission Header Section */}
      <Section>
        <div className="max-w-4xl mx-auto">
          {/* Vision and Mission Banner Image */}
          <div className="mb-16 relative">
            <img
              src={img1}
              alt="Vision and Mission"
              className="w-full h-96 object-contain rounded-lg shadow-lg mb-12"
            />
            
            <h1 className="text-5xl font-bold mb-12 text-center">Mission & Vision</h1>
          </div>
          
          {/* Mission Section */}
          <div className="mb-16 flex md:flex-row flex-col items-center gap-12">
            <div className="md:w-1/2">
              <h2 className="text-3xl font-bold mb-6">Our Mission</h2>
              <p className="text-lg text-gray-600 mb-8 leading-relaxed">
                To provide transformative education and resources, empowering individuals and communities 
                to thrive by nurturing talents, fostering innovation, and creating self-sustaining models 
                of learning and development.
              </p>
            </div>
            
          </div>
  
          {/* Vision Section */}
          <div className="mb-16 flex md:flex-row-reverse flex-col items-center gap-12">
            <div className="md:w-1/2">
              <h2 className="text-3xl font-bold mb-6">Our Vision</h2>
              <p className="text-lg text-gray-600 mb-8 leading-relaxed">
                To build a society where every individual, regardless of background, has access to quality 
                education, opportunities for growth, and the resources to become ethical, capable, and 
                globally responsible citizens.
              </p>
            </div>
          </div>
        </div>
      </Section>
  
      {/* Objectives Section */}
      <Section className="bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold mb-12 text-center">Our Objectives</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {objectives.map((objective, index) => (
              <div 
                key={index} 
                className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow"
              >
                {/* Objective Image */}
                <div className="h-48 overflow-hidden">
                  <img
                    src={objective.image}
                    alt={objective.title}
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                {/* Objective Content */}
                <div className="p-8">
                  <h3 className="text-2xl font-bold mb-4">{objective.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{objective.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default MissionVisionPage;