import { useState } from "react";
import Button from "../SharedComponents/Button";
import NavLink from "../SharedComponents/NavLink";
import { X, Menu } from 'lucide-react';
import { Link } from "react-router-dom";
import img from "../photos/logo.png";

const Navigation = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const navItems = [
      { path: "/about", label: "About" },
      { path: "/mission-vision", label: "Mission & Vision" },
      { path: "/programs", label: "Programs" },
      { path: "/initiatives", label: "Initiatives" },
      { path: "/mentors", label: "Mentors" },
      { path: "/contact", label: "Contact" }
    ];
  
    return (
      <nav className="fixed w-full bg-white/90 backdrop-blur-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <Link to="/" className="flex items-center space-x-3">
              <img 
                src={img}
                alt="Adroid Foundation Logo"
                className="w-20 h-20 object-contain"
              />
              <span className="text-2xl font-bold">Adroid Foundation</span>
            </Link>
  
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              {navItems.map((item) => (
                <NavLink key={item.path} to={item.path}>
                  {item.label}
                </NavLink>
              ))}
              <Link to="/support">
                <Button>Support Us</Button>
              </Link>
            </div>
  
            {/* Mobile Menu Button */}
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)} 
              className="md:hidden p-2"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
  
        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-white px-4 pt-6 pb-8 shadow-lg rounded-b-lg">
            <div className="space-y-6">
              {navItems.map((item) => (
                <NavLink 
                  key={item.path} 
                  to={item.path}
                  className="block font-medium text-gray-800 hover:text-primary-600"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.label}
                </NavLink>
              ))}
              <Link 
                to="/support" 
                onClick={() => setIsMenuOpen(false)}
              >
                <Button className="w-full mt-4">Support Us</Button>
              </Link>
            </div>
          </div>
        )}
      </nav>
    );
  };
  
  export default Navigation;