import Section from "../SharedComponents/Section";
import img1 from "../photos/integrity.png";
import img2 from "../photos/inclusivity.png";
import img3 from "../photos/innovation.png";
import img4 from "../photos/journey.png";
import img5 from "../photos/purpose.png";
const AboutPage = () => {
  const values = [
    {
      title: "Integrity",
      description: "Upholding transparency and ethical practices in all we do.",
      image: img1
    },
    {
      title: "Inclusivity",
      description: "Welcoming diversity and making education accessible to all.",
      image: img2
    },
    {
      title: "Innovation",
      description: "Implementing forward-thinking methodologies in teaching and community development.",
      image: img3
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      {/* Journey and Purpose Section */}
      <Section>
        <h1 className="text-5xl font-bold mb-12">About Us</h1>
        <div className="grid md:grid-cols-2 gap-12">
          {/* Our Journey */}
          <div className="space-y-6">
            <div className="rounded-lg overflow-hidden mb-6">
              <img
                src={img4}
                alt="Our Journey"
                className="w-full h-64 object-contain"
              />
            </div>
            <h2 className="text-3xl font-bold">Our Journey</h2>
            <p className="text-lg text-gray-600">
              Founded with the vision of transforming communities through education, 
              Adroid Foundation was established to provide quality education accessible to every child. 
              Inspired by the teachings of Japanese and Montessori pedagogies, our journey began with 
              a mission to bridge the gap between traditional education and real-world skills.
            </p>
          </div>

          {/* Our Purpose */}
          <div className="space-y-6">
            <div className="rounded-lg overflow-hidden mb-6">
              <img
                src={img5}
                alt="Our Purpose"
                className="w-full h-64 object-contain"
              />
            </div>
            <h2 className="text-3xl font-bold">Our Purpose</h2>
            <p className="text-lg text-gray-600">
              Adroid Foundation is driven by the purpose of nurturing talents, encouraging creative 
              expression, and building self-sufficiency through targeted educational and community initiatives.
            </p>
          </div>
        </div>
      </Section>

      {/* Values Section */}
      <Section className="bg-gray-50">
        <h2 className="text-4xl font-bold mb-12">Our Values</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {values.map((value, index) => (
            <div key={index} className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
              {/* Value Image */}
              <div className="h-48 overflow-hidden">
                <img
                  src={value.image}
                  alt={value.title}
                  className="w-full h-full object-contain transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              {/* Value Content */}
              <div className="p-8">
                <h3 className="text-2xl font-bold mb-4">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default AboutPage;