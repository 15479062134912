import Section from "../SharedComponents/Section";
import img1 from "../photos/program1.png";
import img2 from "../photos/program2.png";
import img3 from "../photos/program3.png";
import img4 from "../photos/program4.png";
const ProgramsPage = () => {
  const programs = [
    {
      title: "Early Years (Ages 3-6)",
      description: "Focus on foundational skills through interactive, play-based activities and guided exploration.",
      image: img1
    },
    {
      title: "Primary School (Ages 7-11)",
      description: "Building on the foundation with project-based learning, promoting collaboration, and creativity.",
      image: img2
    },
    {
      title: "Secondary School (Ages 12-16)",
      description: "Focus on skill-building, encouraging entrepreneurial thinking, and preparing students for the workforce.",
      image: img3
    },
    {
      title: "Senior Secondary School (Ages 17-18)",
      description: "Specialized programs to help students explore career paths, including workshops on financial literacy, IT, arts, and vocational skills.",
      image: img4
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      <Section>
        <h1 className="text-5xl font-bold mb-16 text-center">Our Programs</h1>
        
        <div className="grid md:grid-cols-2 gap-12">
          {programs.map((program, index) => (
            <div 
              key={index} 
              className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow group"
            >
              {/* Program Image */}
              <div className="h-64 overflow-hidden relative">
                <img
                  src={program.image}
                  alt={program.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
                {/* Age Overlay */}
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
                  <h3 className="text-2xl font-bold">{program.title}</h3>
                </div>
              </div>
              
              {/* Program Description */}
              <div className="p-8">
                <p className="text-gray-600 leading-relaxed">
                  {program.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Program Overview Section */}
        <Section className="mt-16 bg-gray-50 rounded-lg">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Our Educational Approach</h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              We provide a comprehensive educational journey that adapts to the evolving needs of students 
              at each stage of their development. Our programs are designed to nurture curiosity, 
              critical thinking, and personal growth while preparing students for future challenges.
            </p>
          </div>
        </Section>
      </Section>
    </div>
  );
};

export default ProgramsPage;